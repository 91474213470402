"use strict";

export class Generality {
    constructor(notification) {
        this.notification = notification;
    }

    /** Al presionar el botón submit de un formulario se deshabilita */
    disableForm() {
        document.querySelectorAll(".form-main").forEach((form) => {
            form.addEventListener("submit", (event) => {
                document.querySelectorAll(".btn-form").forEach((button) => {
                    button.disabled = true;
                    button.classList.add("cursor-not-allowed");

                    setTimeout((error) => {
                        button.disabled = false;
                        button.classList.remove("cursor-not-allowed");
                    }, 60000);
                });
            });
        });
    }

    /** Método que permite mostrar un botón que al presionarlo va a la parte superior de la página */
    goUp() {
        if (document.body.contains(document.querySelector("#buttonTop"))) {
            let buttonTop = document.querySelector("#buttonTop");

            window.onscroll = function () {
                if (
                    document.body.scrollTop > 20 ||
                    document.documentElement.scrollTop > 20
                ) {
                    buttonTop.classList.remove("hidden");
                } else {
                    buttonTop.classList.add("hidden");
                }
            };

            buttonTop.addEventListener("click", (event) => {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            });
        }
    }

    /** Método que aplica al reenviar una solicitud */
    resendApplication() {
        document.querySelectorAll(".resendApplication").forEach((item) => {
            item.addEventListener("click", (event) => {
                let id = item.getAttribute("id");
                let question = this.notification.showSwalConfirm(
                    "¡No!",
                    "#16A34B",
                    "Sí, procede",
                    "<p class='m-0 text-center'>Recuerde revisar que su información este completa y sea la correcta.</p>",
                    "success",
                    "<p class='m-0 text-center'>¿Realmente desea reenviar esta solicitud?</p>"
                );

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                id,
                            }),
                            method: "put",
                            url: `/applications/resend/${id}`,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(
                                    () =>
                                        (location.href = `/applications/${id}`),
                                    1500
                                );
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha enviado la solicitud, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se ha enviado la solicitud porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se ha enviado la solicitud porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se ha enviado la solicitud porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha enviado la solicitud, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha enviado la solicitud, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se ha enviado la solicitud porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    } else {
                        this.notification.showToasted(
                            "alive",
                            "error",
                            "<p class='m-0 text-center'>La solicitud no ha sido enviada.</p>"
                        );
                    }
                });
            });
        });
    }

    /** Método que aplica a elementos tipo 'select' */
    selectConfig() {
        document.querySelectorAll(".selectr").forEach((item) => {
            let id = item.getAttribute("id");

            try {
                new Selectr(document.getElementById(id));
            } catch (error) {
                console.log(error);
            }
        });
    }

    /** Método que aplica al enviar una solicitud */
    sendApplication() {
        document.querySelectorAll(".sendApplication").forEach((item) => {
            item.addEventListener("click", (event) => {
                let id = item.getAttribute("id");
                let url =
                    "/storage/doc/condiciones_para_la_eleccion_del_tramite_de_la_matricula_profesional.pdf";
                let question = this.notification.showSwalConfirm(
                    "¡No!",
                    "#16A34B",
                    "Sí, procede",
                    `<p class='m-0 text-justify'><span class='mt-1 font-bold'>Una vez radicada la solicitud no se puede cambiar el tipo de trámite</span>, recuerde revisar que su información este completa y correcta, en caso de encontrar algún dato que no corresponda al tipo de trámite de la solicitud, esta se resolverá rechazandola, por favor revise las siguientes indicaciones para mayor información antes de radicar su solicitud: <a class='text-blue-cpqcol-1000 font-bold' target='_blank' rel='noopener' href='${url}'>Ver indicaciones</a>.</p>`,
                    "success",
                    "<p class='m-0 text-center'>¿Realmente desea radicar esta solicitud?</p>"
                );

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                id,
                            }),
                            method: "put",
                            url: `/applications/send/${id}`,
                        })
                            .then((response) => {
                                this.notification.showToasted(
                                    "alive",
                                    "success",
                                    `<p class='p-0'>${response.data}</p>`
                                );
                                setTimeout(
                                    () =>
                                        (location.href = `/applications/${id}`),
                                    1500
                                );
                            })
                            .catch((error) => {
                                let message;

                                switch (error.response.status) {
                                    case 400:
                                        message =
                                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha radicado la solicitud, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 403:
                                        message =
                                            '<p class="m-0">Acceso denegado.</p>';
                                        break;

                                    case 404:
                                        message =
                                            '<p class="m-0">No se ha radicado la solicitud porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 405:
                                        message =
                                            '<p class="m-0">No se ha radicado la solicitud porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;

                                    case 500:
                                        message =
                                            '<p class="m-0">No se ha radicado la solicitud porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 504:
                                        message =
                                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha radicado la solicitud, por favor vuelve a intentarlo.</p>';
                                        break;

                                    case 509:
                                        message =
                                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha radicado la solicitud, por favor vuelve a intentarlo.</p>';
                                        break;

                                    default:
                                        message =
                                            '<p class="m-0">No se ha radicado la solicitud porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                        break;
                                }

                                this.notification.showToasted(
                                    "alive",
                                    "error",
                                    message
                                );
                            });
                    } else {
                        this.notification.showToasted(
                            "alive",
                            "error",
                            "<p class='m-0 text-center'>La solicitud no ha sido radicada.</p>"
                        );
                    }
                });
            });
        });
    }
}
