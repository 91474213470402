window._ = require("lodash");

import Axios from "axios";
import Flowbite from "flowbite/dist/flowbite.js";
import Selectr from "mobius1-selectr";
import Toasted from "toastedjs";
import Swal from "sweetalert2";

import { Elimination } from "./other/eliminations.js";
import { Generality } from "./other/generalities.js";
import { Notification } from "./other/notifications.js";
import { Payment } from "./other/payments.js";
import { Region } from "./other/regions.js";
import { Selection } from "./other/selections.js";

try {
    window.axios = Axios;
    window.Flowbite = Flowbite;
    window.Selectr = Selectr;
    window.Toasted = Toasted;
    window.Swal = Swal;

    const notification = new Notification();

    const elimination = new Elimination(notification);
    const generality = new Generality(notification);
    const payment = new Payment(notification);
    const region = new Region(notification);
    const selection = new Selection(notification);

    window.onload = () => {
        /** Métodos para eliminar */
        elimination.destroyAcademicTitle();
        elimination.destroyApplication();
        elimination.destroyDegree();
        elimination.destroyGender();
        elimination.destroyIdentificationType();
        elimination.destroyInstitution();
        elimination.destroyJob();
        elimination.destroyLabor();
        elimination.destroyPayment();
        elimination.destroyRecords();
        elimination.destroyRecordsAdmin();
        elimination.destroyStudy();
        elimination.destroyUser();

        /** Métodos generales */
        generality.disableForm();
        generality.goUp();
        generality.resendApplication();
        generality.selectConfig();
        generality.sendApplication();

        /** Métodos para gestionar los pagos */
        payment.chooseMethod();

        /** Métodos para selección de datos desde un select */
        selection.changeUser();
        selection.chooseAcademicTitle();
        selection.chooseApplications();
        selection.chooseInstitution();
        selection.chooseProcedure();
        selection.chooseRole();
        selection.chooseRoleTwo();
        selection.chooseStage();
        selection.chooseStageCard();
        selection.recognitionOnCreate();
        selection.recognitionOnEdit();

        /** Métodos para selección de regiones */
        region.chooseCountry();
        region.chooseCountryThree();
        region.chooseCountryTwo();
    };
} catch (e) {}

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
} else {
    console.error(
        "CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token"
    );
}
