"use strict";

export class Payment {
    constructor(notification) {
        this.notification = notification;
    }

    /** Método que aplica al elegir el tipo de pago */
    chooseMethod() {
        document.querySelectorAll(".btnPayment").forEach((item) => {
            item.addEventListener("click", (event) => {
                let application_id = item.getAttribute("id");

                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        confirmButton:
                            "inline-flex w-full mx-1 px-3 py-1 text-sm font-bold text-white duration-300 bg-blue-600 border border-blue-700 rounded-md hover:scale-105 md:w-auto",
                        cancelButton:
                            "inline-flex w-full mx-1 px-3 py-1 text-sm font-bold text-white duration-300 bg-green-600 border border-green-700 rounded-md hover:scale-105 md:w-auto",
                    },
                    buttonsStyling: false,
                });

                swalWithBootstrapButtons
                    .fire({
                        imageUrl:
                            "https://multimedia.epayco.co/epayco-landing/btns/epayco-logo-fondo-oscuro-lite.png",
                        imageAlt: "epayco",
                        title: "Elija el método de pago",
                        showCancelButton: true,
                        confirmButtonText: "Pago en efectivo",
                        cancelButtonText:
                            "Pago con tarjeta crédito/débito, pse",
                        reverseButtons: true,
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.start(application_id, "agregador");
                        } else if (
                            result.dismiss === Swal.DismissReason.cancel
                        ) {
                            this.start(application_id, "gateway");
                        }
                    });
            });
        });
    }

    /** Método que aplica al iniciar el pago de una solicitud */
    start(application_id, type) {
        axios({
            body: JSON.stringify({
                application_id,
                type,
            }),
            method: "post",
            url: `/payments/store/${application_id}/${type}`,
        })
            .then((response) => {
                let object = response.data;

                let handler = ePayco.checkout.configure({
                    key: object.key,
                    test: true,
                });

                let data = {
                    /** Parametros obligatorios para comenzar la transacción */
                    name: `Pago ${object.procedure_name}`,
                    description: `Pago ${object.procedure_name}`,
                    invoice: object.payment_id,
                    currency: "cop",
                    amount: object.amount,
                    tax_base: "0",
                    tax: "0",
                    country: "co",
                    lang: "es",

                    /** 'true' para mostrar en epayco, 'false' para no salir de la página */
                    external: "false",

                    /** Parametros opcionales */
                    extra1: object.email,
                    extra2: object.landline,

                    /** Url para que la pasarela informe a la plataforma los datos de la transacción */
                    confirmation: object.confirmation,

                    /** Url para que la pasarela redireccione de nuevo al portal informando el estado de la transacción al solicitante */
                    response: object.response,

                    /** Parametros del solicitante */
                    name_billing: object.full_name,
                    address_billing: object.address,
                    type_doc_billing: object.identification_type,
                    email_billing: object.email,
                    mobilephone_billing: String(object.cell_phone),
                    number_doc_billing: object.identification,

                    /** Atributo para deshabilitar métodos de pago */
                    //methodsDisable: ["TDC", "PSE", "SP", "CASH", "DP"]
                };

                /** Dispara el evento que activa el iframe de la pasarela */
                handler.open(data);
            })
            .catch((error) => {
                let message;

                switch (error.response.status) {
                    case 400:
                        message =
                            '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha iniciado el pago, por favor vuelve a intentarlo.</p>';
                        break;

                    case 403:
                        message = '<p class="m-0">Acceso denegado.</p>';
                        break;

                    case 404:
                        message =
                            '<p class="m-0">No se ha iniciado el pago porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                        break;

                    case 405:
                        message =
                            '<p class="m-0">No se ha iniciado el pago porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                        break;

                    case 500:
                        message =
                            '<p class="m-0">No se ha iniciado el pago porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                        break;

                    case 504:
                        message =
                            '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha iniciado el pago, por favor vuelve a intentarlo.</p>';
                        break;

                    case 509:
                        message =
                            '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha iniciado el pago, por favor vuelve a intentarlo.</p>';
                        break;

                    default:
                        message =
                            '<p class="m-0">No se ha iniciado el pago porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                        break;
                }

                this.notification.showToasted("alive", "error", message);
            });
    }
}
