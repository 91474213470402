"use strict";

export class Notification {
    /** Alerta informativa grande */
    showSwal(confirmButtonColor, html, icon, title) {
        Swal.fire({
            backdrop: "rgba(0, 0, 0, 0.95)",
            background: "#FFFFFF",
            confirmButtonColor: confirmButtonColor,
            customClass: "md:w-1/2 w-11/12",
            html: html,
            icon: icon,
            title: title,
        });
    }

    /** Alerta de confirmación grande */
    showSwalConfirm(
        cancelButtonText,
        confirmButtonColor,
        confirmButtonText,
        html,
        icon,
        title
    ) {
        return Swal.fire({
            cancelButtonText: cancelButtonText,
            confirmButtonColor: confirmButtonColor,
            confirmButtonText: confirmButtonText,
            customClass: "md:w-1/2 w-11/12",
            focusConfirm: false,
            html: html,
            icon: icon,
            showCancelButton: true,
            showCloseButton: true,
            title: title,
        });
    }

    /** Alerta informativa pequeña */
    showToasted(theme, type, message) {
        const toasted = new Toasted({
            duration: 5000,
            position: "bottom-left",
            theme: theme,
            type: type,
        });

        toasted.show(message);
    }
}
